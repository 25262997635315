import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Cards from "../components/cards";

export default function Page({ data }) {
  const page = data.wpPage;
  return (
    <Layout>
      <Seo seo={page.seo} />
      <header className="page__featured-image__wrapper ">
        <div className="page__featured-image__overlay" />
        <img
          className="page__featured-image"
          src={page?.featuredImage?.node?.sourceUrl}
          alt=""
        />
        {page.showTitleText.showTitleText && (
          <h2 className="page__title">{page.title}</h2>
        )}
      </header>
      <main className="wysiwyg__content__wrapper pt-6">
        <div
          className="wysiwyg__content"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </main>

      {page.cards?.cards && (
        <Cards type={page.cards?.type} cards={page.cards?.cards} />
      )}

      {page.slug === "investment-banking" && (
        <div className="wysiwyg__content__wrapper mb-10 text-center ">
          <Link
            className="text-blue page__text"
            to="/services/selected-transactions/all"
          >
            <u>See selected transactions</u>
          </Link>
        </div>
      )}

      <div className="mb-10" />
    </Layout>
  );
}

export const query = graphql`
  query ($databaseId: Int!) {
    wpPage(databaseId: { eq: $databaseId }) {
      title
      slug
      featuredImage {
        node {
          sourceUrl
        }
      }
      showTitleText {
        fieldGroupName
        showTitleText
      }
      content
      seo {
        fullHead
      }
      cards {
        type
        cards {
          text
          title
          backgroundColour
          linkUri
          image {
            sourceUrl
          }
        }
      }
    }
  }
`;
